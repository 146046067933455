import "./App.css";
import Header from "./Header";
import Herosection from "./Herosection";
import Model_popup from "./Modal_popup";
import Footer from "./components/Footer";
import Homepage from "./components/Homepage";
import Navbar from "./components/Navbar";

function App() {
  return (
    <div>
      <Model_popup />
      <Navbar />
      <Homepage />
      <Footer />
    </div>
  );
}

export default App;
