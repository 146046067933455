import React from "react";

const Footer = () => {
  return (
    <div className="footer">
      <div className="container-fluid">
        <div className="row">
          <div className="col-12">
            <div className="disclimer">
              Disclaimer The Project is being Developed by Mahindra lifespaces.
              The Project is registered as "Mahindra Zen" with RERA No:
              PRM/KA/RERA/1251/310/PR/210324/006712, available at
              website:http://rera.karnataka.gov.in. The information is presented
              as general information and no warranty is expressly or impliedly
              given that the completed development will comply in any degree
              with such artist’s impression or anticipated appearance.Recipients
              are advised to apprise themselves of the necessary and relevant
              information of the project(s)/offer(s) prior to making any
              purchase decisions. The Sale is subject to terms of Application
              Form and Agreement for Sale. All specifications of the unit shall
              be as per the final agreement between the Parties. *The prices
              mentioned are an indicative Agreement Value. Stamp Duty
              Registration, GST and Other Charges over and above the Agreement
              Value. The amenities mentioned here are a part of Mahindra Zen and
              proposed future development. This is an initiative by Smita an
              Individual Channel Partner RERA Regn No.
              PRM/KA/RERA/1251/310/AG/220521/002906 to provide information about
              Mahindra Zen is not to be construed as an official site of
              Mahindra Zen. Distance mentioned is as per google map. Drive time
              refers to the time taken to travel by a car based on normal
              traffic conditions during non-peak hours as per Google maps may
              vary according to the current traffic conditions. Information
              provided is based on Online sources, the developer does not
              provide any guarantee on the same.
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
