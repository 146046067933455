import React from "react";

export default function Model_popup() {
  return (
    <div>
      <div className="model_pageload">
        <div
          class="modal fade "
          id="pageload_model"
          tabindex="-1"
          role="dialog"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div class="modal-dialog modal-dialog-centered" role="document">
            <div class="modal-content">
              <div class="p-3">
                <button
                  type="button"
                  class="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body text-center">
                {/* <div className="good_news">Good news</div> */}
                {/* <img
                  src="./images/popupImage.jpg"
                  alt=""
                  className="img-fluid"
                /> */}

                <video width="750" height="500" controls className="img-fluid">
                  <source src="./images/promo_video.mp4" type="video/mp4" />
                </video>

                <div className="phase_two">
                  <h1>
                    Show flat open now! <br />
                  </h1>
                  <h3>Visit soon...</h3>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
