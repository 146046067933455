import React from "react";

const Navbar = () => {
  return (
    <div>
      <nav class="navbar navbar-expand-lg navbar-light bg-light fixed-top">
        <a class="navbar-brand" href="#">
          <img
            src="./images/mahindra_logo_new_horizontal-1-scaled-1.webp"
            className="img-fluid"
            alt=""
          />
        </a>
        <button
          class="navbar-toggler "
          type="button"
          data-toggle="collapse"
          data-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span class="navbar-toggler-icon"></span>
        </button>

        <div class="collapse navbar-collapse" id="navbarSupportedContent">
          <ul class="navbar-nav ml-auto">
            <li class="nav-item ">
              <a class="nav-link" href="#">
                OVERVIEW
              </a>
            </li>
            <li class="nav-item">
              <a class="nav-link" href="#experienceHomes">
                EXPERIENCE HOMES
              </a>
            </li>
            <li class="nav-item">
              <a class="nav-link" href="#amenities">
                AMENITIES
              </a>
            </li>
            <li class="nav-item">
              <a class="nav-link" href="#location">
                LOCATION
              </a>
            </li>
            <li class="nav-item">
              <a class="nav-link" href="#gallery">
                GALLERY
              </a>
            </li>
            <li class="nav-item">
              <a class="nav-link" href="#sustanibility">
                SUSTAINABILITY
              </a>
            </li>
          </ul>
        </div>
      </nav>
    </div>
  );
};

export default Navbar;
