import React from "react";
import Slider from "react-slick";
import ImageGallery from "react-image-gallery";
import GetInTouch from "./getInTouch";
import Sustanibility from "./sustanibility";

const Homepage = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 3,
  };

  const settings_banner = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 4,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 3,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const exterior = [
    {
      original: "./images/gallery/Entry-Gate.webp",
      thumbnail: "./images/gallery/Entry-Gate.webp",
    },
    {
      original: "./images/gallery/Birds-eye-View.webp",
      thumbnail: "./images/gallery/Birds-eye-View.webp",
    },
    {
      original: "./images/gallery/Mid-Aerial-View.webp",
      thumbnail: "./images/gallery/Mid-Aerial-View.webp",
    },
    {
      original: "./images/gallery/Elevation_01.webp",
      thumbnail: "./images/gallery/Elevation_01.webp",
    },
    {
      original: "./images/gallery/Elevation_02-1.webp",
      thumbnail: "./images/gallery/Elevation_02-1.webp",
    },
  ];

  const interior = [
    {
      original: "./images/gallery/View-from-Balcony.jpg",
      thumbnail: "./images/gallery/View-from-Balcony.jpg",
    },
    {
      original: "./images/gallery/Living-Room.webp",
      thumbnail: "./images/gallery/Living-Room.webp",
    },
    {
      original: "./images/gallery/Kitchen.webp",
      thumbnail: "./images/gallery/Kitchen.webp",
    },
    {
      original: "./images/gallery/Master-Bedroom.webp",
      thumbnail: "./images/gallery/Master-Bedroom.webp",
    },
    {
      original: "./images/gallery/Bedroom.webp",
      thumbnail: "./images/gallery/Bedroom.webp",
    },
    {
      original: "./images/gallery/Kids-Bedroom.webp",
      thumbnail: "./images/gallery/Kids-Bedroom.webp",
    },

    {
      original: "./images/gallery/Guest-Bedroom.webp",
      thumbnail: "./images/gallery/Guest-Bedroom.webp",
    },
  ];

  const amenities = [
    {
      original: "./images/gallery/Clubhouse-1.webp",
      thumbnail: "./images/gallery/Clubhouse-1.webp",
    },
    {
      original: "./images/gallery/Cricket.webp",
      thumbnail: "./images/gallery/Cricket.webp",
    },
    {
      original: "./images/gallery/Banyan-tree.webp",
      thumbnail: "./images/gallery/Banyan-tree.webp",
    },
    {
      original: "./images/gallery/Zen-finity-walkway.webp",
      thumbnail: "./images/gallery/Zen-finity-walkway.webp",
    },
    {
      original: "./images/gallery/Barefoot-walk.webp",
      thumbnail: "./images/gallery/Barefoot-walk.webp",
    },
    {
      original: "./images/gallery/Kids-play-area.webp",
      thumbnail: "./images/gallery/Kids-play-area.webp",
    },
    {
      original: "./images/gallery/Tree-kids-play.webp",
      thumbnail: "./images/gallery/Tree-kids-play.webp",
    },
    {
      original: "./images/gallery/Swimming-pool-1.webp",
      thumbnail: "./images/gallery/Swimming-pool-1.webp",
    },
    {
      original: "./images/gallery/Meditation-Corner.webp",
      thumbnail: "./images/gallery/Meditation-Corner.webp",
    },
    {
      original: "./images/gallery/Multipurpose-sports-court.webp",
      thumbnail: "./images/gallery/Multipurpose-sports-court.webp",
    },

    {
      original: "./images/gallery/Open-yoga-deck.webp",
      thumbnail: "./images/gallery/Open-yoga-deck.webp",
    },
  ];

  return (
    <div>
      <button
        type="button"
        class="btn btn-primary"
        data-toggle="modal"
        data-target="#exampleModal"
      >
        <div class="demo bottomright">Enquire Now</div>
      </button>

      <div
        class="modal fade popup "
        id="exampleModal"
        tabindex="-1"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-dialog-centered" role="document">
          <div class="modal-content">
            <div class="p-2">
              <button
                type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body enquiery_form">
              <h3>Enquire Now</h3>
              <p>
                Please fill in your details below and we will get in touch with
                you shortly
              </p>

              <form
                name="contact"
                method="post"
                data-netlify="true"
                onSubmit="submit"
                data-netlify-honeypot="bot-field"
              >
                <input type="hidden" name="form-name" value="contact" />
                <div className="row">
                  <div className="col">
                    <input
                      required
                      type="text"
                      className="form-control"
                      placeholder="Name"
                      name="name"
                    />
                  </div>
                  <div className="col">
                    <input
                      required
                      type="text"
                      className="form-control"
                      placeholder="Phone Number"
                      name="phonenumber"
                    />
                  </div>
                </div>
                <div className="row mt-3">
                  <div className="col">
                    <input
                      required
                      type="text"
                      className="form-control"
                      placeholder="Email"
                      name="email"
                    />
                  </div>
                  <div className="col">
                    <fieldset>
                      <div className="some-className">
                        <label>Need Brochure</label>{" "}
                        <br className="mobile-break"></br>
                        <input
                          type="radio"
                          className=" ml-0 ml-md-3 mr-1"
                          name="brochure"
                          value="Yes"
                        />
                        <label for="y">Yes</label>
                        <input
                          type="radio"
                          className="radio ml-3 mr-1"
                          name="brochure"
                          value="No"
                        />
                        <label for="no">No</label>
                      </div>
                    </fieldset>
                  </div>
                </div>
                <div className="row mt-3">
                  <div className="col">
                    <input
                      type="text"
                      required
                      className="form-control"
                      placeholder="Message"
                      name="message"
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-12 text-center">
                    <button type="submit" className="btn check_price_btn mt-4">
                      Submit
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>

      <div className="homeBanner">
        <div className="container-fluid ">
          <div className="row">
            <div className="col-md-12 p-0 desktop">
              <img
                src="./images/Mahindra-Zen-D.webp"
                className="img-fluid"
                alt=""
              />
            </div>
            <div className="col-md-12 p-0 mobile">
              <img
                src="./images/Mahindra-Zen-M.webp"
                className="img-fluid"
                alt=""
              />
            </div>
          </div>
        </div>
      </div>

      <div className="banner_highlights">
        <div className="container">
          <div className="slider-container">
            <Slider {...settings_banner}>
              <div className="border_right">
                <h5>Location</h5>
                <h6>Off Hosur Road, Bengaluru</h6>
              </div>
              <div className="text-center border_right">
                <h5>Price Starting</h5>
                <h6>₹ 1.99 Cr</h6>
              </div>
              <div className="text-center border_right">
                <h5>Configuration</h5>
                <h6>3, 3.5 & 4 BHK</h6>
              </div>
              <div className="text-center">
                <h5>Towers</h5>
                <h6>2</h6>
              </div>
            </Slider>
          </div>
        </div>
      </div>

      <div className="banner_highlights_2">
        <div className="container">
          <div className="row">
            <div className="col-12 text-center">
              <h1>
                A vibrant kaleidoscope to replenish your mind, body and soul
                with the benefits of nature’s five elements - Earth, Sky, Wind,
                Fire, and Water.
              </h1>
            </div>
          </div>
        </div>
      </div>
      <hr />

      {/* Experience Homes */}

      <div className="experience_homes" id="experienceHomes">
        <div className="container">
          <div className="row">
            <div className="col-md-12 text-center">
              <h2>Your Life-Affirming Oasis Awaits</h2>
              <p>
                Relish unhindered vistas and the utmost privacy in
                multi-storeyed, spaciously designed towers. Your exclusive haven
                has been constructed with meticulous care, in harmony with lush
                and open green spaces.
              </p>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <nav>
                <div class="nav nav-tabs" id="nav-tab" role="tablist">
                  <a
                    class="nav-item nav-link active"
                    id="nav-home-tab"
                    data-toggle="tab"
                    href="#nav-home"
                    role="tab"
                    aria-controls="nav-home"
                    aria-selected="true"
                  >
                    INTERACTIVE UNIT PLAN
                  </a>
                  <a
                    class="nav-item nav-link"
                    id="nav-profile-tab"
                    data-toggle="tab"
                    href="#nav-profile"
                    role="tab"
                    aria-controls="nav-profile"
                    aria-selected="false"
                  >
                    MASTER PLAN
                  </a>
                </div>
              </nav>
              <div class="tab-content" id="nav-tabContent">
                <div
                  class="tab-pane fade show active pt-3"
                  id="nav-home"
                  role="tabpanel"
                  aria-labelledby="nav-home-tab"
                >
                  <ul class="nav nav-tabs" id="myTab" role="tablist">
                    <li class="nav-item">
                      <a
                        class="nav-link active nav_btn"
                        id="home-tab"
                        data-toggle="tab"
                        href="#home"
                        role="tab"
                        aria-controls="home"
                        aria-selected="true"
                      >
                        3BHK PARK
                      </a>
                    </li>
                    <li class="nav-item">
                      <a
                        class="nav-link nav_btn"
                        id="profile-tab"
                        data-toggle="tab"
                        href="#profile"
                        role="tab"
                        aria-controls="profile"
                        aria-selected="false"
                      >
                        3.5 BHK MEADOWS
                      </a>
                    </li>
                    <li class="nav-item">
                      <a
                        class="nav-link nav_btn"
                        id="contact-tab"
                        data-toggle="tab"
                        href="#contact"
                        role="tab"
                        aria-controls="contact"
                        aria-selected="false"
                      >
                        4 BHK MEADOWS
                      </a>
                    </li>
                  </ul>
                  <div class="tab-content" id="myTabContent">
                    <div
                      class="tab-pane fade show active pt-3"
                      id="home"
                      role="tabpanel"
                      aria-labelledby="home-tab"
                    >
                      <div className="row">
                        <div className="col-md-4 align-self-center order-2 order-md-1">
                          <h5>RERA Carpet Area </h5>
                          <h6>
                            <b>110.07 Sqm</b> (1184.78 SQ.FT.)
                          </h6>

                          <h5>EBVT Area </h5>
                          <h6>
                            <b>14.15 Sqm</b> (152.31 SQ.FT.)
                          </h6>

                          <h5>Aggregate Area</h5>
                          <h6>
                            <b>124.22 Sqm </b> (1337.09 SQ.FT.)
                          </h6>
                          <a
                            type="button"
                            class="btn check_price_btn"
                            data-toggle="modal"
                            data-target="#exampleModal"
                          >
                            Check Price
                          </a>
                        </div>
                        <div className="col-md-8 order-1 order-md-2">
                          <img
                            src="./images/Unit-plan_3BHK-PARK-Unit-No.-3-scaled.jpg"
                            className="img-fluid"
                            alt=""
                          />
                        </div>
                      </div>
                    </div>
                    <div
                      class="tab-pane fade pt-3"
                      id="profile"
                      role="tabpanel"
                      aria-labelledby="profile-tab"
                    >
                      <div className="row">
                        <div className="col-md-4 align-self-center order-2 order-md-1">
                          <h5>RERA Carpet Area </h5>
                          <h6>
                            <b>119.93 Sqm</b> (1290.91 SQ.FT.)
                          </h6>

                          <h5>EBVT Area </h5>
                          <h6>
                            <b>18.42 Sqm</b> (198.27 SQ.FT.)
                          </h6>

                          <h5>Aggregate Area</h5>
                          <h6>
                            <b>138.35 Sqm </b> (1489.19 SQ.FT.)
                          </h6>
                          <a
                            type="button"
                            class="btn check_price_btn"
                            data-toggle="modal"
                            data-target="#exampleModal"
                          >
                            Check Price
                          </a>
                        </div>
                        <div className="col-md-8 order-1 order-md-2">
                          <img
                            src="./images/Unit-plan_3.5-BHK-MEADOWS-UNIT-NO.-4-scaled.jpg"
                            className="img-fluid"
                            alt=""
                          />
                        </div>
                      </div>
                    </div>
                    <div
                      class="tab-pane fade pt-3"
                      id="contact"
                      role="tabpanel"
                      aria-labelledby="contact-tab"
                    >
                      <div className="row">
                        <div className="col-md-4 align-self-center order-2 order-md-1">
                          <h5>RERA Carpet Area </h5>
                          <h6>
                            <b>136.95 Sqm</b> (1474.12 SQ.FT.)
                          </h6>

                          <h5>EBVT Area </h5>
                          <h6>
                            <b>19.86 Sqm</b> (213.77 SQ.FT.)
                          </h6>

                          <h5>Aggregate Area</h5>
                          <h6>
                            <b>156.81 Sqm </b> (1687.89 SQ.FT.)
                          </h6>
                          <a
                            type="button"
                            class="btn check_price_btn"
                            data-toggle="modal"
                            data-target="#exampleModal"
                          >
                            Check Price
                          </a>
                        </div>
                        <div className="col-md-8 order-1 order-md-2">
                          <img
                            src="./images/Unit-plan_4-BHK-MEADOWS-UNIT-NO.-2-scaled.jpg"
                            className="img-fluid"
                            alt=""
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-8">
                  <div class="tab-content" id="myTabContent2">
                    <div
                      class="tab-pane fade show active pt-3"
                      id="home"
                      role="tabpanel"
                      aria-labelledby="home-tab"
                    ></div>
                    <div
                      class="tab-pane fade"
                      id="profile"
                      role="tabpanel"
                      aria-labelledby="profile-tab"
                    ></div>
                    <div
                      class="tab-pane fade"
                      id="contact"
                      role="tabpanel"
                      aria-labelledby="contact-tab"
                    ></div>
                  </div>
                </div>
                <div
                  class="tab-pane fade pt-3"
                  id="nav-profile"
                  role="tabpanel"
                  aria-labelledby="nav-profile-tab"
                >
                  <img
                    src="./images/gallery/Malgudi-Master-Plan.webp"
                    className="img-fluid"
                    alt=""
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Amenities */}

      <div className="amenities" id="amenities">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <h4>
                Mahindra Zen: Where modern living meets the rejuvenating power
                of nature
              </h4>
              <p className="text-center">
                You are invited to experience ‘Zen’ living, a way of life that
                did not seem possible in a city anymore. Homes at Mahindra Zen
                blend ancient wisdom with modern aesthetics, serving as your
                private sanctums that recharge you everyday.
              </p>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <div className="slider-container">
                <Slider {...settings}>
                  <div>
                    <div className="row">
                      <div className="col-12">
                        <h3>
                          <img
                            src="./images/Aqua-Fitness-1.png"
                            className="img-fluid"
                            alt=""
                          />
                          <h5>Aqua Fitness</h5>
                        </h3>
                      </div>
                      <div className="col-12 mt-5">
                        <h3>
                          <img
                            src="./images/Camping-Zone.png"
                            className="img-fluid"
                            alt=""
                          />
                          <h5>Camping Zone</h5>
                        </h3>
                      </div>
                    </div>
                  </div>
                  <div>
                    <div className="row">
                      <div className="col-12">
                        <h3>
                          <img
                            src="./images/Badminton-Court.png"
                            className="img-fluid"
                            alt=""
                          />
                          <h5>Badminton Court</h5>
                        </h3>
                      </div>
                      <div className="col-12 mt-5">
                        <h3>
                          <img
                            src="./images/Contemplation-Zone.png"
                            className="img-fluid"
                            alt=""
                          />
                          <h5>Contemplation Zone</h5>
                        </h3>
                      </div>
                    </div>
                  </div>
                  <div>
                    <div className="row">
                      <div className="col-12">
                        <h3>
                          <img
                            src="./images/Barefoot-Walk-Hugging-Tree.png"
                            className="img-fluid"
                            alt=""
                          />
                          <h5>Barefoot Walk & Hugging Tree</h5>
                        </h3>
                      </div>
                      <div className="col-12 mt-5">
                        <h3>
                          <img
                            src="./images/Cricket-Pitch.png"
                            className="img-fluid"
                            alt=""
                          />
                          <h5>Cricket Pitch</h5>
                        </h3>
                      </div>
                    </div>
                  </div>
                  <div>
                    <div className="row">
                      <div className="col-12">
                        <h3>
                          <img
                            src="./images/Flame-Meditation-Corner.png"
                            className="img-fluid"
                            alt=""
                          />
                          <h5>Flame Meditation Corner</h5>
                        </h3>
                      </div>
                      <div className="col-12 mt-5">
                        <h3>
                          <img
                            src="./images/Jogging-Track.png"
                            className="img-fluid"
                            alt=""
                          />
                          <h5>Jogging Track</h5>
                        </h3>
                      </div>
                    </div>
                  </div>
                  <div>
                    <div className="row">
                      <div className="col-12">
                        <h3>
                          <img
                            src="./images/Indoor-Games-Room.png"
                            className="img-fluid"
                            alt=""
                          />
                          <h5>Indoor Games Room</h5>
                        </h3>
                      </div>
                      <div className="col-12 mt-5">
                        <h3>
                          <img
                            src="./images/Kids-Activity-Room.png"
                            className="img-fluid"
                            alt=""
                          />
                          <h5>Kids Activity Room</h5>
                        </h3>
                      </div>
                    </div>
                  </div>
                  <div>
                    <div className="row">
                      <div className="col-12">
                        <h3>
                          <img
                            src="./images/Indoor-Gym.png"
                            className="img-fluid"
                            alt=""
                          />
                          <h5>Indoor Gym</h5>
                        </h3>
                      </div>
                      <div className="col-12 mt-5">
                        <h3>
                          <img
                            src="./images/Kids-Play-Area.png"
                            className="img-fluid"
                            alt=""
                          />
                          <h5>Kids Play Area</h5>
                        </h3>
                      </div>
                    </div>
                  </div>
                  <div>
                    <div className="row">
                      <div className="col-12">
                        <h3>
                          <img
                            src="./images/Massage-Room.png"
                            className="img-fluid"
                            alt=""
                          />
                          <h5>Massage Room</h5>
                        </h3>
                      </div>
                      <div className="col-12 mt-5">
                        <h3>
                          <img
                            src="./images/Nature-Corridor.png"
                            className="img-fluid"
                            alt=""
                          />
                          <h5>Nature Corridor</h5>
                        </h3>
                      </div>
                    </div>
                  </div>
                  <div>
                    <div className="row">
                      <div className="col-12">
                        <h3>
                          <img
                            src="./images/Meditation-Corner.png"
                            className="img-fluid"
                            alt=""
                          />
                          <h5>Meditation Corner</h5>
                        </h3>
                      </div>
                      <div className="col-12 mt-5">
                        <h3>
                          <img
                            src="./images/Nature-Trail.png"
                            className="img-fluid"
                            alt=""
                          />
                          <h5>Nature Trail</h5>
                        </h3>
                      </div>
                    </div>
                  </div>
                  <div>
                    <div className="row">
                      <div className="col-12">
                        <h3>
                          <img
                            src="./images/Party-Hall.png"
                            className="img-fluid"
                            alt=""
                          />
                          <h5>Party Hall</h5>
                        </h3>
                      </div>
                      <div className="col-12 mt-5">
                        <h3>
                          <img
                            src="./images/Senior-Citizen-Area.png"
                            className="img-fluid"
                            alt=""
                          />
                          <h5>Senior Citizen Area</h5>
                        </h3>
                      </div>
                    </div>
                  </div>
                  <div>
                    <div className="row">
                      <div className="col-12">
                        <h3>
                          <img
                            src="./images/Pet-Park.png"
                            className="img-fluid"
                            alt=""
                          />
                          <h5>Pet Park</h5>
                        </h3>
                      </div>
                      <div className="col-12 mt-5">
                        <h3>
                          <img
                            src="./images/Serenity-Bistro.png"
                            className="img-fluid"
                            alt=""
                          />
                          <h5>Serenity Bistro</h5>
                        </h3>
                      </div>
                    </div>
                  </div>
                  <div>
                    <div className="row">
                      <div className="col-12">
                        <h3>
                          <img
                            src="./images/Restorative-Seating.png"
                            className="img-fluid"
                            alt=""
                          />
                          <h5>Restorative Seating</h5>
                        </h3>
                      </div>
                      <div className="col-12 mt-5">
                        <h3>
                          <img
                            src="./images/Skylight-Library.png"
                            className="img-fluid"
                            alt=""
                          />
                          <h5>Skylight Library</h5>
                        </h3>
                      </div>
                    </div>
                  </div>
                  <div>
                    <div className="row">
                      <div className="col-12">
                        <h3>
                          <img
                            src="./images/Solar-Powered-Working-Pods.png"
                            className="img-fluid"
                            alt=""
                          />
                          <h5>Solar Powered Working Pods</h5>
                        </h3>
                      </div>
                      <div className="col-12 mt-5">
                        <h3>
                          <img
                            src="./images/Symphony-Garden.png"
                            className="img-fluid"
                            alt=""
                          />
                          <h5>Symphony Garden</h5>
                        </h3>
                      </div>
                    </div>
                  </div>
                  <div>
                    <div className="row">
                      <div className="col-12">
                        <h3>
                          <img
                            src="./images/Surya-Namaskar-Zone.png"
                            className="img-fluid"
                            alt=""
                          />
                          <h5>Surya Namaskar Zone</h5>
                        </h3>
                      </div>
                      <div className="col-12 mt-5">
                        <h3>
                          <img
                            src="./images/Urban-Forest.png"
                            className="img-fluid"
                            alt=""
                          />
                          <h5>Urban Forest</h5>
                        </h3>
                      </div>
                    </div>
                  </div>
                  <div>
                    <div className="row">
                      <div className="col-12">
                        <h3>
                          <img
                            src="./images/Swimming-Pool.png"
                            className="img-fluid"
                            alt=""
                          />
                          <h5>Swimming Pool</h5>
                        </h3>
                      </div>
                      <div className="col-12 mt-5">
                        <h3>
                          <img
                            src="./images/Zen-finity-Walkway.png"
                            className="img-fluid"
                            alt=""
                          />
                          <h5>Zen-finity Walkway</h5>
                        </h3>
                      </div>
                    </div>
                  </div>
                </Slider>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Location */}

      <div className="location" id="location">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <h4>Location </h4>
              <div className="google-maps">
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d5344.0531557576805!2d77.64206487052826!3d12.872704663554766!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bae6b62f46da2b7%3A0x13737559f4080bce!2sMahindra%20Zen!5e0!3m2!1sen!2sin!4v1711194539075!5m2!1sen!2sin"
                  style={{ border: 0 }}
                  width="100%"
                  height="440"
                  allowfullscreen=""
                  loading="lazy"
                  referrerpolicy="no-referrer-when-downgrade"
                ></iframe>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Gallery */}

      <div className="gallery" id="gallery">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <h5>Mahindra Zen Gallery</h5>
              <h6>Catch glimpses of a balanced life.</h6>
            </div>
          </div>

          <div className="row">
            <div className="col-md-12">
              <nav>
                <div class="nav nav-tabs" id="nav-tab" role="tablist">
                  <a
                    class="nav-item nav-link active"
                    id="gallery_exterior"
                    data-toggle="tab"
                    href="#nav-ext"
                    role="tab"
                    aria-controls="nav-home"
                    aria-selected="true"
                  >
                    Exterior
                  </a>
                  <a
                    class="nav-item nav-link"
                    id="gallery_interior"
                    data-toggle="tab"
                    href="#nav-int"
                    role="tab"
                    aria-controls="nav-profile"
                    aria-selected="false"
                  >
                    Interior
                  </a>
                  <a
                    class="nav-item nav-link"
                    id="gallery_special_amenities"
                    data-toggle="tab"
                    href="#nav-amenities"
                    role="tab"
                    aria-controls="nav-amenities"
                    aria-selected="false"
                  >
                    Special Amenities
                  </a>
                </div>
                <div class="tab-content" id="nav-tabContent">
                  <div
                    class="tab-pane fade show active"
                    id="nav-ext"
                    role="tabpanel"
                    aria-labelledby="gallery_exterior"
                  >
                    <ImageGallery
                      showBullets={false}
                      showThumbnails={false}
                      showPlayButton={false}
                      items={exterior}
                    />
                  </div>
                  <div
                    class="tab-pane fade"
                    id="nav-int"
                    role="tabpanel"
                    aria-labelledby="gallery_interior"
                  >
                    <ImageGallery
                      showBullets={false}
                      showThumbnails={false}
                      showPlayButton={false}
                      items={interior}
                    />
                  </div>
                  <div
                    class="tab-pane fade"
                    id="nav-amenities"
                    role="tabpanel"
                    aria-labelledby="gallery_special_amenities"
                  >
                    <ImageGallery
                      showBullets={false}
                      showThumbnails={false}
                      showPlayButton={false}
                      items={amenities}
                    />
                  </div>
                </div>
              </nav>
            </div>
          </div>
        </div>
      </div>

      {/* Sustanibility */}
      <Sustanibility />

      <GetInTouch />
    </div>
  );
};

export default Homepage;
