import React from "react";

const getInTouch = () => {
  return (
    <div>
      <div className="getInTouch">
        <div className="container-fluid">
          <div className="row ">
            <div className="col-12 ">
              <h5>Get in touch with us</h5>
            </div>
          </div>
          <nav>
            <div class="nav nav-tabs" id="nav-tab" role="tablist">
              <a
                class="nav-item nav-link active"
                id="enqueryNow"
                data-toggle="tab"
                href="#enqueryNow1"
                role="tab"
                aria-controls="enqueryNow1"
                aria-selected="true"
              >
                ENQIRE NOW
              </a>
              {/* <a
                class="nav-item nav-link"
                id="tellUs2"
                data-toggle="tab"
                href="#tellUs1"
                role="tab"
                aria-controls="tellUs1"
                aria-selected="false"
              >
                TELL US WHAT YOU WANT
              </a> */}
            </div>
          </nav>
          <div class="tab-content getInTouch_bg" id="nav-tabContent">
            <div
              class="tab-pane fade show active "
              id="enqueryNow1"
              role="tabpanel"
              aria-labelledby="enqueryNow2"
            >
              <div className="container">
                <form
                  name="contact"
                  method="post"
                  data-netlify="true"
                  onSubmit="submit"
                  data-netlify-honeypot="bot-field"
                >
                  <input type="hidden" name="form-name" value="contact" />
                  <div className="row">
                    <div className="col">
                      <input
                        required
                        type="text"
                        className="form-control"
                        placeholder="Name"
                        name="name"
                      />
                    </div>
                    <div className="col">
                      <input
                        required
                        type="text"
                        className="form-control"
                        placeholder="Phone Number"
                        name="phonenumber"
                      />
                    </div>
                  </div>
                  <div className="row mt-3">
                    <div className="col">
                      <input
                        required
                        type="text"
                        className="form-control"
                        placeholder="Email"
                        name="email"
                      />
                    </div>
                    <div className="col">
                      <fieldset>
                        <div className="some-className">
                          <label>Need Brochure</label>{" "}
                          <br className="mobile-break"></br>
                          <input
                            type="radio"
                            className=" ml-0 ml-md-3 mr-1"
                            name="brochure"
                            value="Yes"
                          />
                          <label for="y">Yes</label>
                          <input
                            type="radio"
                            className="radio ml-3 mr-1"
                            name="brochure"
                            value="No"
                          />
                          <label for="no">No</label>
                        </div>
                      </fieldset>
                    </div>
                  </div>
                  <div className="row mt-3">
                    <div className="col">
                      <input
                        type="text"
                        required
                        className="form-control"
                        placeholder="Message"
                        name="message"
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12 text-center">
                      <button type="submit" className="btn btn-primary mt-4">
                        Submit
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
            <div
              class="tab-pane fade"
              id="tellUs1"
              role="tabpanel"
              aria-labelledby="tellUs2"
            >
              234
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default getInTouch;
