import React from "react";
import Slider from "react-slick";

const sustanibility = () => {
  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  var Halfsettings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    centerMode: true,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 3,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          centerMode: false,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <div>
      <div
        class="modal fade"
        id="IGBCPlatinum"
        tabindex="-1"
        role="dialog"
        aria-labelledby="IGBCPlatinum"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-dialog-centered" role="document">
          <div class="modal-content">
            <div class="p-3">
              <button
                type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <img
                src="./images/GH2300257_PreCert.webp"
                className="img-fluid"
                alt=""
              />
            </div>
          </div>
        </div>
      </div>

      <div
        class="modal fade"
        id="NetZeroWaste"
        tabindex="-1"
        role="dialog"
        aria-labelledby="NetZeroWaste"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-dialog-centered" role="document">
          <div class="modal-content">
            <div class="p-3">
              <button
                type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <img
                src="./images/CII-IGBC-Green-Net-Zero-Waste-to-Landfill-NZWL-C-1.webp"
                className="img-fluid"
                alt=""
              />
            </div>
          </div>
        </div>
      </div>

      <div
        class="modal fade"
        id="NetZeroEnergy"
        tabindex="-1"
        role="dialog"
        aria-labelledby="NetZeroEnergy"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-dialog-centered" role="document">
          <div class="modal-content">
            <div class="p-3">
              <button
                type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <img
                src="./images/CII-IGBC-Green-Net-Zero-Enenrgy-Design-NZE-C-1.webp"
                className="img-fluid"
                alt=""
              />
            </div>
          </div>
        </div>
      </div>

      <div className="sustanibility" id="sustanibility">
        <div className="container">
          <div className="row">
            <div className="col-md-4">
              <h3>In focus: Sustainability at Mahindra Zen</h3>
              <p>
                Experience a sustainable lifestyle with Net Zero, Net Zero
                Waste, and 100% E-Waste management in Mahindra Zen’s IGBC
                pre-certified platinum Net Zero Energy homes. With every step,
                leave a lighter footprint on the planet.
              </p>

              <hr />
              <div className="slider-container">
                <Slider {...settings}>
                  <div>
                    <div className="row">
                      <div className="col-md-3">
                        <img
                          src="./images/IGBC-logo.png"
                          className="img-fluid"
                          alt=""
                        />
                      </div>
                      <div className="col-md-7">
                        <h5>IGBC Platinum</h5>
                        <p>Pre-certified IGBC Platinum rated project</p>
                        <a
                          type="button"
                          class="cursor-pointer"
                          data-toggle="modal"
                          data-target="#IGBCPlatinum"
                        >
                          View Certificate
                        </a>
                      </div>
                    </div>
                  </div>
                  <div>
                    <div className="row">
                      <div className="col-md-3">
                        <img
                          src="./images/IGBC-logo.png"
                          className="img-fluid"
                          alt=""
                        />
                      </div>
                      <div className="col-md-7">
                        <h5>Net Zero Waste - Design & Construction (Design)</h5>
                        <p>Certified by IGBC</p>
                        <a
                          type="button"
                          class="cursor-pointer"
                          data-toggle="modal"
                          data-target="#NetZeroWaste"
                        >
                          View Certificate
                        </a>
                      </div>
                    </div>
                  </div>
                  <div>
                    <div className="row">
                      <div className="col-md-3">
                        <img
                          src="./images/IGBC-logo.png"
                          className="img-fluid"
                          alt=""
                        />
                      </div>
                      <div className="col-md-7">
                        <h5>Net Zero Energy (Design)</h5>
                        <p>Certified by IGBC</p>
                        <a
                          type="button"
                          class="cursor-pointer"
                          data-toggle="modal"
                          data-target="#NetZeroEnergy"
                        >
                          View Certificate
                        </a>
                      </div>
                    </div>
                  </div>
                </Slider>
              </div>
            </div>
            <div className="col-md-8">
              <Slider {...Halfsettings}>
                <div>
                  <div class="flip-card">
                    <div class="flip-card-inner">
                      <div class="flip-card-front">
                        <img
                          src="./images/Power-conservation-big-card.jpg"
                          alt=""
                          className="img-fluid"
                        />
                      </div>
                      <div class="flip-card-back">
                        <h1>Energy Conscious Initiatives</h1>
                        <ul>
                          <li>
                            IGBC pre-certified Net Zero Energy (Design) building
                          </li>
                          <li>
                            Upto 85% of common area energy demand is met through
                            on-site RE integration
                          </li>
                          <li>
                            15% energy savings excluding the on-site renewable
                            energy generation
                          </li>
                          <li>
                            15% energy savings excluding the on-site renewable
                            energy generation
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                <div>
                  <div class="flip-card">
                    <div class="flip-card-inner">
                      <div class="flip-card-front">
                        <img
                          src="./images/Water-and-power-conservation-big-card.jpg"
                          alt=""
                          className="img-fluid"
                        />
                      </div>
                      <div class="flip-card-back">
                        <h1>Water Preservation Protocols</h1>
                        <ul>
                          <li>100% treated water reused on site</li>
                          <li>51% less water used from external sources</li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                <div>
                  <div class="flip-card">
                    <div class="flip-card-inner">
                      <div class="flip-card-front">
                        <img
                          src="./images/Reduce-reuse-recycle-big-card.jpg"
                          alt=""
                          className="img-fluid"
                        />
                      </div>
                      <div class="flip-card-back">
                        <h1>Waste Management Measures</h1>
                        <ul>
                          <li>
                            IGBC pre-certified Net Zero Waste - Design &
                            Construction (Design)
                          </li>
                          <li>
                            More than 90% of the construction & demolition waste
                            is diverted away from landfill
                          </li>
                          <li>100% organic waste composite onsite</li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </Slider>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default sustanibility;
